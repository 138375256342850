@use "../config" as *;
.waf-component {
    &.waf-listing {
        @extend %py-0;
        @extend %my-4;
        .article-title {
            -webkit-line-clamp: 4;
            height: 13rem;
        }
    }
}
.waf-listing {
    &.waf-overlay-list {
        .article-title {
            @extend %neutral-0;
        }
        .pagination-wrap {
            @extend %d-none;
        }
    }
    @include article-listing(card);
    .article-list {
        grid-auto-flow: column;
        grid-auto-columns: calc(100% - var(--space-6));
        grid-template-columns: unset;
        overflow-x: auto;
    }
    .preview-swiper {
        margin-right: calc(-1 * var(--container-white-space));
    }
    .head-wrap {
        flex-direction: column;
        align-items: flex-start;
        .title {
            font-size: 2.4rem;
            line-height: 1.4;
            @include title-with-icon(null, megaphone-fill, 22, null);
            &::before {
                color: var(--neutral-40);
                justify-content: flex-start;
                @extend %pl-2;
                @extend %mb-4;
            }
        }
    }
    .head-tab {
        li a {
            justify-content: flex-start;
            @extend %px-0;
        }
    }
}
@include mq(col-tablet) {
    .waf-component {
        &.waf-listing {
            margin-block: 6.4rem;
        }
    }
    .waf-listing {
        .article-list {
            grid-auto-flow: unset;
            grid-auto-columns: unset;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            overflow-x: unset;
        }
        .preview-swiper {
            margin-right: 0;
        }
        .head-wrap {
            flex-direction: row;
            align-items: flex-end;
            margin-bottom: var(--space-14);
            .title {
                font-size: 4rem;
                line-height: 1.2;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-listing {
        &.waf-overlay-list {
            .article {
                &-title {
                    height: auto;
                    -webkit-line-clamp: 4;
                }
                &-list {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }
            }
        }
    }
}