@use '../config/' as *;
.waf-component {
    &.waf-info-cards {
        @extend %py-0;
        @extend %mb-8;
    }
}
.waf-info-cards {
    @extend %event-card-small;
    .layout-wrapper > .title {
        @extend %d-none;
    }
}
@include mq(col-tablet) {
    .waf-component {
        &.waf-info-cards {
            margin-block: 6.4rem;
        }
    }
    .waf-info-cards {
        .layout-wrapper > .title {
            display: block;
            font-size: 3.2rem;
            line-height: 1.3;
            color: hsl(var(--neutral-100));
            margin-bottom: 3.2rem;
        }
    }
}
@include mq(col-desktop) {
    .waf-info-cards {
        &.waf-multimedia-info-cards {
            .card-list > * {
                width: calc((100% / 3) - var(--space-6));
            }
        }
    }
}